<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('general.employee_time') + ' ' + $t('employee_time.for_employee') + ' ' + employee_name">
            <CustomTable ref="table" :fields="fields" :api-url="`/api/client/v1/employees/time_events/${employeeId}`" >
                <template #buttons>
                    <b-button variant="primary" @click="openAddEventModal">
                        <feather-icon icon="PlusSquareIcon" />
                        {{ $t("general.add_event") }}
                    </b-button>
                </template>
                <template #event_type="{item}">
                    <b-badge v-if="item.event_type === EventType.Work" variant="success">{{getWorkInfo(item)}}</b-badge>
                    <b-badge v-else-if="item.event_type === EventType.Lunch" variant="warning">{{getWorkInfo(item)}}</b-badge>
                    <b-badge v-else-if="item.event_type === EventType.Stopped" variant="danger">{{getWorkInfo(item)}}</b-badge>
                    <div v-else>/</div>
                </template>
                <template #actions="{item}">
                    <span v-if="$hasPermission($permissions.GeneralWrite)">
                        <b-button class="mr-1" size="sm" variant="danger" @click="remove(item.id)">{{ $t('general.delete') }}</b-button>
                        <b-button size="sm" variant="warning" @click="openEditEventModal(item)">{{ $t('general.edit') }}</b-button>
                    </span>
                </template>
            </CustomTable>
        </b-card>

        <template v-if="$hasPermission($permissions.GeneralWrite)">
            <AddEventModal ref="addEventModal" v-on:itemAdded="loadData" />
            <EditEventModal ref="editEventModal" v-on:itemEdited="loadData" />
        </template>
    </b-overlay>
</template>

<script>
    import EditEventModal from '@/views/Employee/components/EditEventModal.vue'
    import {BBadge, BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import {EmployeeTimeEvents, EventType} from '@/libs/enums/EmployeeTimeEvent'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'
    import AddEventModal from '@/views/Employee/components/AddEventModal.vue'

    export default {
        components: {AddEventModal, BBadge, CustomTable, EditEventModal, BCard, BOverlay, BButton},
        data() {
            return {
                employee: null,
                employeeId: '',
                employeePermissionGroups: [],
                showLoader: false,
                fields: [
                    {
                        key: 'event_type',
                        label: this.$t('table_fields.time_event_work'),
                        filter: true,
                        filter_type: 'select',
                        filterParams: EmployeeTimeEvents,
                        filterParamsName: 'name',
                        reduce: x => x.value,
                        permissions: true
                    },
                    {
                        key: 'timestamp',
                        label: this.$t('table_fields.date'),
                        filter: true,
                        permissions: true,
                        formatter: Formaters.formatDate,
                        filter_type: 'date',
                        min_width: 150
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true
                    }
                ]
            }
        },
        computed: {
            employee_name() {
                return this.employee ? this.employee.name : ''
            },
            EventType() {
                return EventType
            }
        },
        methods: {
            async loadEmployeeData() {
                try {
                    const res = await this.$http.get('/api/client/v1/employees/')
                    this.employee = res.data.find(x => x.id === this.employeeId)

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load'))
                }
            },
            loadData() {

                this.$refs.table.loadData()
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/employees/time_events/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            openAddEventModal() {
                this.$refs.addEventModal.open(this.employeeId)
            },
            openEditEventModal(event) {
                this.$refs.editEventModal.open(event)
            },
            getWorkInfo(value) {
                if (value) {
                    if (value.event_type === EventType.Work) return `${this.$t('employee_time.time_event_work')}`
                    else if (value.event_type === EventType.Lunch) return `${this.$t('employee_time.time_event_lunch')}`
                    else if (value.event_type === EventType.Stopped) return `${this.$t('employee_time.time_event_stop')}`
                }
                return '/'
            }
        },
        async mounted() {
            this.employeeId = this.$route.params.id
            this.loadEmployeeData()
        }
    }
</script>

<style scoped>

</style>
