<template>
    <b-modal :title="$t('general.change_time')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('general.time_event_work')">
                    <validation-provider #default="{ errors }" :name="$t('employee_time.time_event_work')" rules="required">
                        <v-select v-model="event.event_type" appendToBody label="name" :options="EmployeeTimeEvens()" :reduce="x => x.value" :clearable="false"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('general.date_time')">
                    <validation-provider #default="{ errors }" :name="$t('general.date_time')" rules="required">
                        <date-picker :first-day-of-week="2" v-model="event.timestamp" time-accuracy="3" :popover="popoverOptions" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" mode="dateTime" :is24hr="true" :hide-time-header="true" :is-required="true">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="$dayjs(inputValue).format('DD.MM.YYYY, HH:mm:ss')"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="add">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('general.add') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BModal} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {DatePicker} from 'v-calendar'
    import {required, dateTimeBefore, dateTimeAfter} from '@validations'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'
    import {EmployeeTimeEvents} from '@/libs/enums/EmployeeTimeEvent'

    export default {
        components: {
            BFormGroup,
            BModal,
            BButton,
            DatePicker,
            vSelect,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            employeePermissionGroups: {
                type: Array
            }
        },
        data() {
            return {
                modalActive: false,
                event: {
                    employee_id: '',
                    event_type: '',
                    timestamp: ''
                },
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                popoverOptions: {
                    positionFixed: true
                },
                required,
                dateTimeBefore,
                dateTimeAfter
            }
        },
        methods: {
            EmployeeTimeEvens() {
                return EmployeeTimeEvents
            },
            open(employeeId) {
                this.event = {
                    employee_id: employeeId,
                    event_type: '',
                    timestamp: new Date()
                }
                this.modalActive = true
            },
            async add() {
                try {
                    const valid = await this.$refs.validation.validate()
                    if (!valid) return

                    await this.$http.post('/api/client/v1/employees/time_events/', this.event)
                    this.modalActive = false
                    this.event = {
                        employee_id: '',
                        event_type: '',
                        timestamp: new Date()
                    }
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.add'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>